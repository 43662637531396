import React, { useEffect, useState } from 'react'

import { useClearOrderStorage } from '../../model/store'
import { Button2 } from '../../../../../components/button2'
import { SuccessStatusIcon } from '../../components/icons'
import { ThankYouTemplate } from '../../components/templates/thank-you-template'

export const ThankYouPaymentReceivedPage = () => {
  const { clearOrderStorage } = useClearOrderStorage

  useEffect(() => {
    clearOrderStorage()
  }, [])

  const handleClose = () => {
    window.location.href = '/#calculator'
  }

  const [orderId, setOrderId] = useState('')
  const [orderData, setOrderData] = useState({})
  const [orderIdFromData, setOrderIdFromData] = useState('')
  const [message, setMessage] = useState(null)

  async function getOrderParams(id) {
    await fetch(`/api/check-online-payment?id=${id}`)
      .then(res => res.json())
      .then(data => setOrderData(data))
  }

  useEffect(() => {
    let urlString = document.location.href
    let url = new URL(urlString)
    let id = url.searchParams.get('id')
    console.log('check-online-payment search param id', id)
    id && setOrderId(id)
  }, [])

  useEffect(() => {
    console.log('check-online-payment orderId', orderId)
    orderId !== '' && getOrderParams(orderId)
  }, [orderId])

  useEffect(() => {
    console.log('check-online-payment data', orderData)
    if (Object.keys(orderData).length !== 0) {
      // cj.order
      setOrderIdFromData(orderData?.orderId)
      if (orderData?.payment_status && orderData?.payment_status !== '') {
        setMessage(orderData?.payment_status)
      } else if (orderData?.error && orderData?.error?.msg) {
        setMessage(orderData?.error?.msg)
      } else {
        setMessage('Sommething went wrong')
      }
      if (orderData?.cjevent && orderData.cjevent !== '') {
        window.cj.order.orderId = orderData.orderId
        window.cj.order.userId = orderData.userId
        window.cj.order.amount = orderData.amount
        window.cj.order.cjevent = orderData.cjevent
        window.cj.order.coupon = orderData.coupon
        console.log('gonna redirect to affiliate TY')
        window.location.href = `/thank-you-affiliate/?status=${orderData.payment_status}&orderId=${orderData.orderId}&userId=${orderData.userId}&amount=${orderData.amount}&cjevent=${orderData.cjevent}&coupon=${orderData.coupon}`
      }
    }
  }, [orderData])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []

    window.dataLayer &&
      window.dataLayer.push({
        event: 'purchase',
        pageURL: window.location.pathname,
      })
  }, [])

  return (
    <ThankYouTemplate onClose={handleClose}>
      <header>
        <SuccessStatusIcon />
        <h1>Děkujeme, vaši platbu jsme úspěšně přijali.</h1>
      </header>
      <p>
        Vaši objednávku začínáme zpracovávat. Toto potvrzení jsme vám odeslali
        také na e-mail.
      </p>
      {/*<p>*/}
      {/*  Vaši objednávku <span>20220341</span> začínáme zpracovávat. Toto*/}
      {/*  potvrzení jsme vám odeslali také na e-mail.*/}
      {/*</p>*/}
      <Button2 color="secondary" onClick={handleClose}>
        Zpět na hlavní stránku
      </Button2>
    </ThankYouTemplate>
  )
}
